<template>
  <div id="app">
    <iconsCollection />
    <div
      v-show="sidebarOpened"
      class="sidebar-fader"
      @click="closeSidebar"
    ></div>
    <sideBar v-if="$route.meta.layout != 'auth'" />
    <main :class="$route.meta.layout">
      <floatingBar v-if="$route.meta.layout != 'auth'" />
      <div
        :class="{ container: $route.meta.layout != 'auth' }"
        style="padding: 0"
      >
        <notificationsBar />
        <router-view />
      </div>
      <auth-modal />
    </main>
  </div>
</template>

<script>
import sideBar from "@/layout/Sidebar.vue";
import iconsCollection from "@/layout/icons/icons-collection.vue";
import floatingBar from "@/layout/floating-bar.vue";
import AuthModal from "./components/modals/authModal.vue";
import notificationsBar from "@/layout/notifications-bar.vue";
// import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  components: {
    sideBar,
    iconsCollection,
    floatingBar,
    AuthModal,
    notificationsBar,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isUser: "isUser",
      sidebarOpened: "sideBarOpened",
    }),
  },
  watch: {
    user: {
      handler() {
        if (this.user) {
          this.getUserData();
        }
      },
    },
    $route: {
      handler() {
        this.closeSidebar();
      },
    },
  },
  methods: {
    async getNotifications() {
      this.isLoading = true;
      try {
        this.$api
          .get("/notifications/get/")
          .then((res) => {
            if (res.data.success) {
              this.$store.dispatch(
                "setNotificationsCount",
                res.data.notifications.length
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });

        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    closeSidebar() {
      this.$store.dispatch("toggleSidebar", false);
    },
    async getUserData() {
      var data = await this.$db.getDocument("users", this.user.uid);
      this.$store.dispatch("setUserData", data);
      let now = new Date();
      await this.$db.update("users", this.user.uid, {
        last_visit: now,
      });
    },
    getDataSets() {
      this.$store.dispatch("getDatasets");
    },
  },
  mounted() {
    this.getDataSets();
    this.$auth.onUserAuthChange();
    this.getNotifications();
  },
};
</script>
<style lang="scss">
body {
  background: #f8f8f8 !important;
  font-size: 16px;
}
.sidebar-fader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(#000, 0.3);
  z-index: 9998;
  cursor: pointer;
}
main {
  margin-left: var(--sidebar-width);
  padding: 0px 20px;
  padding-top: 96px;
  padding-bottom: 40px;
  box-sizing: border-box;
  min-height: 100vh;
  background: #fff;
  @media (max-width: 991px) {
    margin-left: 0;
  }
  &.sidebar {
    background: #f8f8f8;
  }
  &.auth {
    margin-left: 0;
    padding: 0;
    background: #fff;
  }
}
</style>
