<template>
  <div>
    <h1>Мої сповіщення</h1>
    <p>
      Тут Ви отримуватимете сповіщення про дії з Вашими запитами чи зверненнями
    </p>
    <b-overlay
      :show="isLoading"
      :class="{ 'overlay-loading': isLoading }"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <template v-if="notifications.length">
        <notificationItem
          @update="getNotifications"
          v-for="n in orderedNotifications"
          :key="n.id"
          :n="n"
        />
      </template>

      <template v-if="!notifications.length && !isLoading">
        <b-alert show variant="primary">
          <h6 class="mb-0">Немає сповіщень</h6>
        </b-alert>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notificationItem from "@/components/notifications/notification-list-item.vue";
import _ from "lodash";
export default {
  components: {
    notificationItem,
  },
  data() {
    return {
      isLoading: false,
      notifications: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    orderedNotifications() {
      let notifications = [];

      notifications = _.orderBy(
        this.notifications,
        (item) => item.created_at.seconds,
        "desc"
      );

      return notifications;
    },
  },
  methods: {
    async getNotifications() {
      this.isLoading = true;
      let uid = this.user.uid;
      let args = {
        collectionName: "tickets_events",
        where: ["user", "==", uid],
        where2: ["seen", "==", false],
        where3: ["approved", "==", true],
      };
      try {
        var result = await this.$db.getCollection(args);
        this.notifications = result;
        this.$store.dispatch("setNotificationsCount", result.length);
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>

<style lang="scss" scoped></style>
