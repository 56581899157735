<template>
  <div class="auth-form__login pt-4">
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <form @submit="signIn" @keyup.enter="signIn">
        <div class="form-group">
          <label class="d-block">E-mail</label>
          <input
            type="email"
            placeholder="E-mail"
            v-model="user.email"
            class="d-block w-100"
          />
        </div>
        <div class="form-group">
          <label class="d-block">Пароль</label>
          <input
            type="password"
            placeholder="Пароль"
            v-model="user.password"
            class="d-block w-100"
          />
          <div class="text-end mt-2">
            <router-link :to="{ name: 'forgot-password' }"
              >Забули пароль?</router-link
            >
          </div>
        </div>
        <div class="form-group pt-3">
          <b-button v-ripple @click="signIn" variant="primary" class="w-100">
            Увійти
          </b-button>
        </div>
      </form>
      <div class="form-group">
        <b-button
          variant="outline-primary"
          class="w-100"
          :to="{ name: 'services' }"
          v-if="$route.name == 'Auth'"
        >
          Продовжити без реєстрації
        </b-button>
      </div>
      <small class="user-tip text-center d-block">
        Для доступу до деяких послуг необхідно мати аккаунт
      </small>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["signUpAction", "singInAction"]),
    async signIn() {
      this.isLoading = true;
      var result = await this.$auth.signInWithEmail(
        this.user.email,
        this.user.password
      );
      console.log(result);
      if (result.success == true) {
        this.$toast.success("Ви увійшли");
        this.$bvModal.hide("auth-modal");
        if (this.$route.path.indexOf("auth") > -1) {
          this.$router.push({ name: "services" });
          window.location.reload();
        }
      } else {
        this.$toast.error("Email або пароль не дійсні");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
