import axios from "axios";

const axiosApi = axios.create({
  //Base URL for API calls
  baseURL: "https://us-central1-stryi-citizen.cloudfunctions.net/api",
  // baseURL: "http://localhost:5001/stryi-citizen/us-central1/api",
  // withCredentials: true,
  // headers: headers,
});

//add interceptor to add token to all requests
axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers = headers;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const fileApi = axios.create({
  //Base URL for API calls
  baseURL: "https://project.realno.top/api/file/save/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});
let api = {
  axiosApi: axiosApi,
  fileApi: fileApi,
};

export default api;
