<template>
  <div>
    <template v-if="isVerified">
      <div
        class="d-flex py-2 justify-content-center flex-column align-items-center"
      >
        <img
          src="@/assets/verified.svg"
          style="height: 96px; margin: 20px 0px"
          alt=""
        />
        <h2>Ваш аккаунт верифіковано</h2>
      </div>
    </template>
    <template v-if="!isVerified">
      <h4>
        Для верифікації потрібно прив'язати та підтвердити номер мобільного
        телефону за допомогою СМС- коду
      </h4>
      <p>
        Перед тим як надсилати СМС з кодом, переконайтесь, що телефон поруч з
        Вам та увімкнений
      </p>
      <b-overlay
        :show="isLoading"
        variant="white"
        rounded="sm"
        spinner-variant="warning"
      >
        <div v-show="step == 'phone'" class="pt-2">
          <div class="d-block mb-3">
            <vue-tel-input
              @validate="validatePhone"
              :inputOptions="inputOptions"
              v-model="phone"
            ></vue-tel-input>
          </div>
          <b-button
            :disabled="!phone_obj.valid"
            variant="primary"
            id="verify-button"
            @click="sendCode"
          >
            Отримати СМС з кодом
          </b-button>
        </div>
        <div v-show="step == 'code'" class="pt-2">
          <b-form-input class="mb-2" v-model="code"></b-form-input>
          <b-button
            :disabled="code.length < 5"
            @click="confirmCode"
            variant="primary"
          >
            Підтвердити
          </b-button>
        </div>
      </b-overlay>
    </template>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { mapGetters } from "vuex";
import "@/scss/vue-tel-input.scss";
export default {
  components: {
    VueTelInput,
  },
  data() {
    return {
      isLoading: false,
      step: "phone",
      phone: "",
      code_sent: false,
      code: "",
      confirmationResult: false,
      phone_obj: {
        valid: false,
        number: "",
      },
      inputOptions: {
        dynamicPlaceholder: true,
        placeholder: "Номер телефону",
        enabledCountryCode: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isVerified: "isPhoneVerified",
    }),
  },
  methods: {
    validatePhone(obj) {
      console.log(obj);
      this.phone_obj = obj;
    },
    sendCode() {
      this.isLoading = true;
      var body = {
        phone: this.phone_obj.number,
        uid: this.user.uid,
      };
      this.$api
        .post("/user/verifyPhone/", body)
        .then((response) => {
          if (response.data.status == "pending") {
            this.code_sent = true;
            this.isLoading = false;
            this.step = "code";
          }
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    async confirmCode() {
      this.isLoading = true;
      var body = {
        phone: this.phone_obj.number,
        code: Number(this.code),
        uid: this.user.uid,
      };
      await this.$api
        .post("/user/verifyPhone/confirm/", body)
        .then((response) => {
          console.log(response.data);
          this.isLoading = false;
          if (response.data.status == "approved") {
            window.location.reload();
          }
        });
      this.isLoading = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
