<template>
  <div>
    <h3>
      Тип черги
    </h3>
    <b-form-select
      class="queue-type d-block mb-2 w-100"
      v-model="selectedTable"
    >
      <b-form-select-option :value="t.id" v-for="t in tables" :key="t.id">
        {{ t.name }}
      </b-form-select-option>
    </b-form-select>
    <p class="mb-4">
      Ви також можете подати заявку для того аби стати в чергу на безкоштовне
      житло, скориставшись послугою
      <router-link
        :to="{
          name: 'service-single-local',
          params: { slug: 'dwelling-queue-application' },
        }"
        >Подати заявку на чергу на житло</router-link
      >
    </p>
    <b-overlay spinner-variant="warning" :show="isLoading">
      <!-- <dwellingQueueTable :rowData="rowData" /> -->
    </b-overlay>
  </div>
</template>

<script>
// import dwellingQueueTable from "@/components/local-services/sub-components/dwelling-queue-table.vue";
export default {
  components: {
    // dwellingQueueTable,
  },
  data() {
    return {
      isLoading: false,
      tables: [
        { id: 0, name: "Загальний список" },
        { id: 1, name: "Першочерговий список" },
        { id: 2, name: "Позачеговий список" },
        {
          id: 3,
          name:
            "Особи з інвалідністю в наслідок війни та прирівняні до них особи ",
        },
        { id: 4, name: "Потерпілі від ЧАЕС категорії 1" },
        { id: 5, name: "Переселенці з Чорнобильської зони " },
      ],
      rowData: [],
      selectedTable: 0,
    };
  },
  watch: {
    selectedTable: {
      handler() {
        this.getListData();
      },
    },
  },
  methods: {
    async getListData() {
      this.isLoading = true;
      let id = Number(this.selectedTable) + 1;
      var url = "https://project.realno.top/api/line" + id + "/list/";
      var data = await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          return data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.isLoading = false;
      this.rowData = data;
    },
  },
  mounted() {
    this.getListData();
  },
};
</script>

ls
<style lang="scss" scoped>
.queue-type {
  height: 32px;
  padding:0px 6px;
}
</style>
