<template>
  <div class="auth">
    <div class="blue"></div>
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-12 col-md-5 col-lg-6 auth__column info order-2 order-md-1 flex-1"
          style="overflow: hidden"
        >
          <div class="auth__how-it-works">
            <h3>Як це працює?</h3>
            <p>Особистий кабінет дає можливість:</p>
            <ul>
              <li>
                надсилати звернення до органу місцевого самоврядування та
                отримувати відповіді на них
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-12 col-md-7 col-lg-6 auth__column order-1 order-md-2 flex-1 d-flex align-items-center justify-content-center"
          style="padding-top: 10vh; padding-bottom: 10vh"
        >
          <div class="auth__padding">
            <div class="auth__logo">
              <logo :dark="true" />
            </div>
            <authForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authForm from "@/components/auth-form/auth-form.vue";
import Logo from "@/layout/logo.vue";
export default {
  components: {
    authForm,
    Logo,
  },
  computed: {
    ...mapGetters(["isUser"]),
  },
  beforeMount() {
    if (this.isUser) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style lang="scss" scoped>
.auth {
  position: relative;
  min-height: 100vh;
  height: 100%;
  .container {
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  &__column {
    min-height: 100%;
    overflow: hidden;
    @media (max-width: 768px) {
      padding-top: 60px !important;

      &.info {
        background: linear-gradient(180deg, #224764 0%, #107081 100%);
        padding-bottom: 80px;
      }
    }
  }
  &__padding {
    padding: 30px 60px;
    background: #fff;
    border-radius: 6px;
    @media (max-width: 575px) {
      padding: 20px 20px 60px;
    }
  }
  &__logo {
    margin-bottom: 56px;
  }
  &__how-it-works {
    color: #fff;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.5;
      color: #ffffff;
    }
  }
  .blue {
    background: linear-gradient(180deg, #224764 0%, #107081 100%);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    z-index: 1;
    @media (min-width: 768px) and (max-width: 991px) {
      width: 41.66666667%;
    }
    @media (max-width: 768px) {
      position: relative;
    }
  }
  .container {
    position: relative;
    z-index: 3;
    .row {
      min-height: 100vh;
    }
  }
}
</style>
