<template>
  <div class="form-group">
    <label class="mb-3 mt-4">
      Тема звернення
      <span class="required">*</span>
    </label>
    <v-select
      :disabled="!department"
      id="topic"
      v-model="selected"
      @input="emitSelect"
      :options="topicOptions"
      :reduce="(text) => text.value"
      label="text"
      :placeholder="placeHolder"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Нічого не знайдено
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
export default {
  components: {
    vSelect,
  },
  props: {
    department: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      disableSelect: false,
    };
  },
  watch: {
    department() {
      if (!this.department) {
        this.selected = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),
    placeHolder() {
      return this.department ? "Оберіть тему" : "Оберіть спочатку сферу";
    },
    rootDepartment() {
      let root = {};
      const allDepartments = this.datasets.departments || [];
      if (this.department) {
        const found = allDepartments.find((e) => {
          return e.id == this.department;
        });
        if (found) {
          let rootId = found.parent;
          if (rootId) {
            root = allDepartments.find((e) => {
              return e.id == rootId;
            });
          }
        }
      }
      return root;
    },
    topicOptions() {
      const department = this.rootDepartment;
      const topics = [...this.datasets.topics];
      if (!department.id) {
        return [];
      }
      let list = topics.filter((e) => {
        return e.department_id == department.id;
      });
      list = list.map((e) => {
        return {
          id: e.department_id,
          value: e.name,
          text: e.name,
        };
      });
      list.push({
        id: 9999,
        value: "інше",
        text: "інше",
      });
      list = _.orderBy(list, ["name"], ["asc"]);
      return list;
    },
  },
  methods: {
    emitSelect(value) {
      this.$emit("select", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
