<template>
  <div>
    <h3>Опубліковані звернення</h3>
    <b-overlay :show="isLoading">
      <publishedItem v-for="t in tickets" :key="t.id" :item="t" />

      <b-alert
        :show="!isLoading && dataLoaded && !tickets.length"
        variant="primary"
      >
        Нічого не знайдено
      </b-alert>
    </b-overlay>
  </div>
</template>

<script>
import publishedItem from "@/components/published-ticket-item.vue";
export default {
  components: {
    publishedItem,
  },
  data() {
    return {
      isLoading: false,
      tickets: [],
      dataLoaded: false,
    };
  },
  methods: {
    async getPublishedTickets() {
      this.isLoading = true;
      await this.$api
        .get("/ticket/getPublished")
        .then((res) => {
          if (res.data.success) {
            this.tickets = res.data.tickets;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          this.dataLoaded = true;
        });
    },
  },

  mounted() {
    this.getPublishedTickets();
  },
};
</script>

<style lang="scss" scoped></style>
