<template>
  <b-card class="mb-4">
    <div
      class="ticket__head d-flex flex-column flex-sm-row justify-content-between"
    >
      <router-link
        class="order-2 order-md-1"
        :to="{ name: 'single-ticket', params: { id: t.id } }"
      >
        <h5 class="mb-0" v-if="t.type">{{ getType(t.type) }} #{{ t.id }}</h5>
      </router-link>
      <h6 class="mb-0 order-1 order-sm-2">
        <statusBadge :status="t.status" />
      </h6>
    </div>
    <div class="sender" v-if="t.department">
      <strong>
        {{ getDepartment(t.department).name }}
      </strong>
      <small class="text-muted d-block" v-if="t.topic">{{ t.topic }}</small>
    </div>

    <hr />
    <div
      class="ticket__meta d-flex flex-column flex-sm-row align-items-baseline justify-content-between align-items-center"
    >
      <div class="left mr-auto d-flex align-items-center">
        <strong class="id mr-1 d-block">ID:{{ t.id }}</strong>
      </div>
      <div class="date ml-auto mr-2">
        <strong>
          {{ t.created_at.seconds | moment("calendar") }}
        </strong>
      </div>

      <b-button
        variant="outline-primary"
        size="sm"
        class="small d-sm-flex"
        :to="{ name: 'single-ticket', params: { id: t.id } }"
      >
        Проглянути
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import statusBadge from "@/components/ticket-status-badge";
export default {
  components: {
    statusBadge,
  },
  props: ["t"],
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    statuses() {
      return this.datasets.statuses;
    },
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
  },
  methods: {
    getStatus(id) {
      var status = this.statuses.find((el) => el.id == id);
      return status.name;
    },
    getType(slug) {
      var type = slug;
      if (this.ticket_types) {
        var found = this.ticket_types.find((el) => el.slug.trim() == slug);
        if (found) {
          type = found.name;
        }
      }

      return type;
    },
    getDepartment(id) {
      var department = this.departments.find((el) => el.id == id);
      let parent = this.departments.find((el) => el.id == department.parent);
      return parent || department;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  &__head {
    a {
      text-decoration: none;
    }
    .status-badge {
      @media (max-width: 575px) {
        margin-bottom: 8px;
      }
    }
  }
  &__meta {
    font-size: 14px;
    color: #424242;
    .btn-outline-primary {
      @media (max-width: 575px) {
        min-width: 100% !important;
      }
    }
    .date {
      @media (max-width: 575px) {
        margin-left: 0 !important;
        margin-top: 10px;
        margin-bottom: 16px;
      }
    }
    .id {
      margin-right: 6px;
      color: #999;
    }
  }
}
.btn {
  &.small {
    height: 36px;
    font-size: 16px;
  }
}
</style>
