<template>
  <div class="item mb-4">
    <h5 class="mb-0">
      {{ ticket.topic }}
    </h5>
    <small class="sender mb-2 text-muted" v-if="item.make_author_public">
      Автор:
      <strong>{{ item.sender.first_name }} {{ item.sender.last_name }}</strong>
    </small>
    <p class="pt-2">
      {{ displayText }}
    </p>
    <div class="response mb-2" v-if="expanded">
      <strong> Відповідь: </strong>
      <p>
        {{ item.response_text }}
      </p>
    </div>
    <a href="#" @click.prevent="expanded = !expanded">
      <span v-if="!expanded"> Показати більше </span>
      <span v-else> Показати менше </span>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ticket() {
      return this.item.ticket || {};
    },
    displayText() {
      let text = this.ticket.text || "";
      if (!this.expanded) {
        text = text.slice(0, 200) + "...";
      }
      return text;
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding: 12px;
  border-left: 2px solid #126f81;
  background: #fafafa;
  p {
    color: #666;
  }
  .response {
    font-size: 14px;
    background: #e2faff;
    color: #424242 !important;
    padding: 8px;
    p {
      color: #424242 !important;
      margin-bottom: 0;
    }
  }
}
</style>
