<template>
  <div class="auth-form__register pt-4">
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <div class="d-flex justify-content-between mb-4">
        <div class="form-group">
          <label class="d-block">Прізвище</label>
          <input
            type="email"
            placeholder="Прізвище"
            v-model="user.last_name"
            class="d-block w-100"
          />
        </div>
        <div class="form-group">
          <label class="d-block">Ім'я</label>
          <input
            type="email"
            placeholder="Ім'я"
            v-model="user.first_name"
            class="d-block w-100"
          />
        </div>
      </div>

      <div class="form-group">
        <label class="d-block">E-mail</label>
        <input
          type="email"
          v-model="user.email"
          placeholder="E-mail"
          class="d-block w-100"
        />
      </div>
      <div class="form-group">
        <label class="d-block">Пароль</label>
        <input
          type="password"
          v-model="user.password"
          placeholder="Пароль"
          class="d-block w-100"
        />
      </div>
      <div class="form-group">
        <label class="d-block">Пароль повторно</label>
        <input
          type="password"
          v-model="user.password_repeat"
          placeholder="Пароль повторно"
          class="d-block w-100"
        />
      </div>
      <div class="terms">
        <div class="confirm mr-auto">
          <label for="accept-terms" class="d-flex">
            <b-form-checkbox
              id="accept-terms"
              name="accept-terms"
              v-model="acceptTerms"
            ></b-form-checkbox>
            <span class="ml-1">
              Погоджуюсь з
              <a href="#" @click.prevent="showTerms = !showTerms"
                >умовами користування</a
              >
              та даю згоду на обробку своїх персональних даних
            </span>
          </label>
        </div>
      </div>
      <div class="form-group pt-3">
        <b-button
          :disabled="!acceptTerms"
          @click="signUp()"
          variant="primary"
          class="w-100"
        >
          Зареєструватись
        </b-button>
      </div>
    </b-overlay>
    <b-modal v-model="showTerms" hide-header hide-footer centered>
      <h3>Правила та умови користування сервісом</h3>
      <div class="terms__scroller">
        <div class="terms__content" v-html="terms_content"></div>
      </div>
      <div class="cta pt-3">
        <b-button
          variant="outline-secondary w-100"
          @click="showTerms = !showTerms"
        >
          Закрити
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      showTerms: false,
      acceptTerms: false,
      terms_content: "",
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_repeat: "",
      },
    };
  },
  methods: {
    ...mapActions(["signUpAction", "signInAction"]),
    async getTerms() {
      await this.$api
        .get("/info/terms")
        .then((response) => {
          this.terms_content = response.data.content;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async signUp() {
      this.isLoading = true;
      var result = await this.$auth.createUserWithEmail(
        this.user.email,
        this.user.password
      );
      console.log(result);
      if (result.success) {
        //Create initial data
        var now = new Date();
        var user_data = {
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          uid: result.user.uid,
          first_visit: now,
        };

        //create id
        var id = await this.$api("/get/counter/?type=users")
          .then((response) => {
            return response.data.count;
          })
          .catch((e) => {
            console.log(e);
          });
        if (id) {
          user_data["id"] = id;
        }

        //Write to firestore
        await this.$db.set("users", user_data.uid, user_data);
        // await this.saveUserDataToRemote(user_data);
        this.$toast.success("Реєстрація успішна");
        if (this.$route.name !== "services") {
          this.$router.push({ name: "services" });
        }

        this.$bvModal.hide("auth-modal");
        this.isLoading = false;
      } else {
        var errorText = "";
        if (result.code == "auth/email-already-in-use") {
          errorText = "Цей Email вже використовується";
        }
        if (result.code == "auth/invalid-email") {
          errorText = "Невірно вказано емейл";
        }
        this.$toast.error(errorText);
      }
      this.isLoading = false;
    },
    async saveUserDataToRemote(user) {
      this.isLoading = true;
      user["auto_create"] = "on";

      fetch("https://project.realno.top/api/user/set/", {
        method: "POST", // или 'PUT'
        body: JSON.stringify(user),
        // mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          this.isLoading = false;
          // this.$toast.success("Зміни збережено");
        });
    },
  },
  mounted() {
    this.getTerms();
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__scroller {
    height: 400px;
    overflow-y: scroll;
    background: #f1f1f1;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    //style scrollbar to be 4px width grey bar

    &::-webkit-scrollbar-track {
      background: #ccc;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
    }
  }
}
</style>
